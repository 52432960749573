import React, { Fragment } from "react";
import axios from "axios";
import { render } from "react-dom";
import { Helmet } from 'react-helmet';

import App from "routes";
import { TITLE, FAVICON } from 'constants'

import { requestInterceptor, responseInterceptor } from "./js/interceptor";

axios.interceptors.request.use(requestInterceptor, null);
axios.interceptors.response.use(null, responseInterceptor);

const Root = () => {
  return (
    <Fragment>
      <Helmet>
        <link rel="icon" type="image/png" href={FAVICON} />
        <title>{TITLE}</title>
      </Helmet>
      <App />
    </Fragment>
  )
}

render(<Root />, document.getElementById("root"))