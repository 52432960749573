// PROD setup
const AMIGO_HOST = "https://app.amigocloud.com"

// oauth credentials
const AMIGO_CLIENT_ID = process.env.AMIGO_CLIENT_ID
const AMIGO_CLIENT_SECRET = process.env.AMIGO_CLIENT_SECRET

/*
// LOCAL setup
const AMIGO_HOST = "http://localhost"

// oauth credentials
const AMIGO_CLIENT_ID = "5996bb4af375491b3d95"
const AMIGO_CLIENT_SECRET = "d4235bc6fd279ad93e3afc4f53d650c820d1b97f"

*/

// OAuth URLs
const OAUTH_URL = `${AMIGO_HOST}/api/v1`
const OAUTH_URL_TOKEN = "/oauth2/access_token"

// Map Projects URLs
const GET_PROJECT_URL = `${AMIGO_HOST}/api/v1/projects`
const GET_MAP = `${AMIGO_HOST}/api/v1/maps`

// Tools
const TOOLS = {
  analysis: "analysis",
  map: "map",
}

const RESOURCES_PAGE = "/dashboard"

// Metabase
const METABASE_SITE_URL = "https://dashboard.amigocloud.com"
const METABASE_SECRET_KEY = process.env.METABASE_SECRET_KEY

export {
  TOOLS,
  GET_MAP,
  OAUTH_URL,
  AMIGO_HOST,
  GET_PROJECT_URL,
  OAUTH_URL_TOKEN,
  AMIGO_CLIENT_ID,
  METABASE_SITE_URL,
  METABASE_SECRET_KEY,
  AMIGO_CLIENT_SECRET,
  RESOURCES_PAGE
}
