import React, { Suspense, useState, useEffect } from "react"
import { Router, Redirect } from "@reach/router"

import Spinner from "components/spinner"
import { SessionContext, getSessionCookie, setSessionCookie } from 'context'
import { getPortalUrl } from "utils"
import Axios from "axios"
import { AMIGO_HOST } from "../constants"


const Login = React.lazy(() => import("./login"));
const Dashboard = React.lazy(() => import("./dashboard"));

const PrivateRoute = ({ component: Component, auth, path, ...props }) => {
  if (!auth) return <Redirect from="*" to="/" noThrow />;
  return <Component path={path} {...props} />;
};

const App = () => {
  const [session, setSession] = useState(getSessionCookie())
  const [state, setState] = useState({
    logoUrl: ""
  })
  const { authenticated, user } = session

  useEffect(() => {
    const initialize = async () => {
      const portalResponse = await Axios.get(getPortalUrl())

      setState({
        ...state,
        logoUrl: `${portalResponse.data.organization.logo}`})
    }
    initialize()
  }, [])

  const updateSession = (session, remove = false) => {
    setSessionCookie(session, remove)
  }

  return (
    <SessionContext.Provider value={[session, updateSession]}>
      <Suspense fallback={<Spinner />}>
        <Router style={{ height: "100%" }}>
          <Login path="/" auth={authenticated}/>
          <PrivateRoute
            user={user}
            path="/dashboard/*"
            auth={authenticated}
            component={Dashboard}
            currentLogo={state.logoUrl}
          />
        </Router>
      </Suspense>
    </SessionContext.Provider>
  );
};

export default App;
