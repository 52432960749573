import React from 'react'
import * as Cookies from "js-cookie"

export const setSessionCookie = (session, onlyRemove = false) => {
  Cookies.remove("session")
  if (!onlyRemove) Cookies.set("session", session /*, { secure: true }*/)
}

export const getSessionCookie = () => {
  const sessionCookie = Cookies.get('session')
  return sessionCookie !== undefined ? JSON.parse(sessionCookie) : {}
}

export const SessionContext = React.createContext(getSessionCookie())

export const ProjectsContext = React.createContext()