import jwt from "jsonwebtoken";

import dtprLogo from 'images/logo-dtpr.png'
import dtpmLogo from 'images/logo-dtpm.png'
import {
  METABASE_SITE_URL,
  METABASE_SECRET_KEY,
  LOGO,
  AMIGO_HOST
} from "constants"

const generateUrlMetabase = idDashboard => {
  const payload = {
    params: {},
    resource: { dashboard: idDashboard },
    exp: Math.round(Date.now() / 1000) + 30 * 60
  };
  const token = jwt.sign(payload, METABASE_SECRET_KEY);
  const urlGenerated =
    METABASE_SITE_URL +
    "/embed/dashboard/" +
    token +
    "#bordered=true&titled=true";
  return urlGenerated;
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getCurrentLogo = () => {
  const availableLogos = {
    dtprLogo,
    dtpmLogo
  }

  return availableLogos[LOGO]
}

const getPortalUrl = () => {
  let subdomain = "dtpr"
  if (process.env.NODE_ENV === "production") {
    subdomain = window.location.host.split('.')[0]
  }
  return `${AMIGO_HOST}/api/v2/portals/${subdomain}`
}

const getPortalResourcesUrl = () =>{
  return `${getPortalUrl()}/resources`
}

export {
  generateUrlMetabase,
  capitalizeFirstLetter,
  getCurrentLogo,
  getPortalUrl,
  getPortalResourcesUrl
}
