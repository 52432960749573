import axios from 'axios'
import qs from 'qs'

import { setSessionCookie, getSessionCookie } from 'context'

import { OAUTH_URL, OAUTH_URL_TOKEN, AMIGO_CLIENT_ID, AMIGO_CLIENT_SECRET } from 'constants'

// adding authorization header in interceptor si it is updated on every request
const requestInterceptor = (config) => {
  const session = getSessionCookie()
  if (session && session.authenticated) {
    config.headers['Authorization'] = `Bearer ${session.user.tokens.access_token}`
  }

  return config
}

// in case we get a 403 from amigocloud, we update the access and refresh tokens
const responseInterceptor = async (error) => {
  const session = getSessionCookie()

  if (session && session.authenticated && (
      (error.response && error.response.status === 401) ||
      (error.includes && error.includes('401'))
    )) {
    
    const { refresh_token } = session.user.tokens

    if (refresh_token) {
      return await axios.post(`${OAUTH_URL}${OAUTH_URL_TOKEN}`,
        qs.stringify({
          grant_type: 'refresh_token',
          client_id: AMIGO_CLIENT_ID,
          client_secret: AMIGO_CLIENT_SECRET,
          refresh_token: refresh_token
        }), {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }).then(data => {
          setSessionCookie({
            ...session,
            user: {
              ...session.user,
              tokens: {
                ...session.user.tokens,
                access_token: data.data.access_token,
                refreshToken: data.data.refresh_token
              }
            }
          })
  
          return axios(error.config)
        }).catch(err => {
          setSessionCookie({}, true)
  
          return Promise.reject(error)
        })
    }
  }

  return Promise.reject(error)
}


export {
  requestInterceptor,
  responseInterceptor
}
